.sun-editor .se-list-layer .se-selector-color .se-color-pallet li {
    display: flex;
    float: left;
    position: relative;
    margin: 0px;
    /* padding: 2px; */
    border: 1px solid white;
}

.sun-editor .se-list-layer .se-selector-color {
    display: flex;
    width: max-content;
    max-width: 288px;
    height: auto;
    padding: 0;
    margin: auto;
}


.myCanvas {
    border:1px solid #d3d3d3; cursor: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGNsYXNzPSJpY29uIGljb24tdGFibGVyIGljb24tdGFibGVyLXBlbmNpbCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIGZpbGw9Im5vbmUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PHBhdGggc3Ryb2tlPSJub25lIiBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PHBhdGggZD0iTTQgMjBoNGwxMC41IC0xMC41YTEuNSAxLjUgMCAwIDAgLTQgLTRsLTEwLjUgMTAuNXY0IiAvPjxsaW5lIHgxPSIxMy41IiB5MT0iNi41IiB4Mj0iMTcuNSIgeTI9IjEwLjUiIC8+PC9zdmc+'), auto;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 2px;
    align-items:center;
}

.grid-item {
    text-align: center;
    grid-column: span 2;
}

/* 4번 아이템이 첫 번째 행에서 3열을 차지하도록 설정 */
.item2 {
    grid-column: 1 / span 2;
}

.pen-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 세 개의 칼럼을 동일한 비율로 설정 */
    align-items:center;
}

.pen-item {
    text-align: center; /* 내용을 중앙 정렬 */
}

.color-container {
    display: grid;
    grid-template-columns: 1fr 2fr; /* 두 개의 컬럼으로 구성 */
    grid-template-rows: 1fr 1fr; /* 두 개의 행으로 구성 */
    align-items : center;
}


.color-item {
    /*border: 1px solid #ccc;*/ /* 테두리 설정 */
    text-align: center; /* 텍스트 중앙 정렬 */

}

.left {
    grid-column: 1 / 2; /* 첫 번째 열에 배치 */
    grid-row: 1 / 3; /* 두 행을 차지하도록 설정 */
}

.top-right {
    grid-column: 2 / 3; /* 두 번째 열에 배치 */
    grid-row: 1 / 2; /* 첫 번째 행에 배치 */
}

.bottom-right {
    grid-column: 2 / 3; /* 두 번째 열에 배치 */
    grid-row: 2 / 3; /* 두 번째 행에 배치 */
}

.select_lineWidth {
    width: 130px;
    height: 40px;
    font-size: medium;
    margin-top: 3px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding-left: 10px;
}





/* 스타일링 */
.pen-radio {
    display: inline-block;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

.pen-radio input[type="radio"] {
    display: none; /* 기본 라디오 버튼 숨김 */
}

.pen-radio input[type="radio"] + label {
    display: inline-block;
    padding: 8px 16px;
}

/* 선택된 라디오 버튼의 테두리만 검은색으로 변경 */
.pen-radio input[type="radio"]:checked + label {
    border: 3px solid #AACDEA;
    margin : 1px;
}


/* 스타일링 */
.color-radio {
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
}

.color-radio input[type="radio"] {
    display: none; /* 기본 라디오 버튼 숨김 */
}

.color-radio input[type="radio"] + label {
    display: inline-block;
    padding: 1px 9px;
    border-radius: 20px;
    border: 1px solid #ccc;
}

/* 선택된 라디오 버튼의 테두리만 검은색으로 변경 */
.color-radio input[type="radio"]:checked + label {
    border: 3px solid black;
}


.color-label {
    display: inline-block;
    width: 57px;
    height: 52px;
    background-color: #000000;
    border-radius: 5px;
    border: 3px solid #ccc;
    cursor: pointer;
    /* margin-top: 2px; */
    /* margin-left: 2px; */
    /* margin-right: 2px; */
}

/* 실제 input 태그는 화면에서 숨김 */
.color-input {
    display: block;
    width:0;
    height:0;


}

/* 450px보다 작은 경우 */
@media (max-width: 480px) {
    .color-radio input[type="radio"] + label {
        display: inline-block;
        padding: 1px 5px;
        border-radius: 20px;
        border: 1px solid #ccc;
    }
}

@media (max-width: 360px) {
    .color-radio input[type="radio"] + label {
        display: inline-block;
        padding: 1px 4px;
        border-radius: 20px;
        border: 1px solid #ccc;
    }
}